import OSS from 'ali-oss'

function postImg(token, file, restname) {

    return new Promise(async (resolve, rejext) => {
        let client = new OSS({
            region: 'oss-cn-shanghai',
            accessKeyId: token.AccessKeyId,
            accessKeySecret: token.AccessKeySecret,
            stsToken: token.SecurityToken,
            bucket: "wishmelz",
            secure: true,
            cname: true,
            endpoint: "c.file.itsse.cn"
        })
        let filename = '';

        if (restname == 1) {
            filename = Date.now() + "." + file.name.replace(/.+\./, "");
        } else {
            filename = restname + "." + file.name.replace(/.+\./, "");
        }
        let newDate = new Date();
        let newZDate = new Date();
        let strTime = `${newZDate.getFullYear()}/${(newZDate.getMonth() + 1) > 9 ? (newZDate.getMonth() + 1) : '0' + (newZDate.getMonth() + 1)}${newZDate.getDate() > 9 ? newZDate.getDate() : '0' + newZDate.getDate()}/`
        let strData = `${newDate.getFullYear()}${newDate.getMonth() + 1}${newDate.getDate()}/`
        // new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate()

        try {
            let res = await client.multipartUpload(`/cfwx/` + strTime + filename, file, {
                progress: function (p, checkpoint) {
                    console.log(p); // 序号 
                },
            });
            if (res.res.status == 200) {
                let resUrl = res.res.requestUrls[0];
                resolve(resUrl.split("?")[0])
            }
        } catch (e) {
            rejext(e)
            console.log(e);
        }
    })
}
export default postImg