<template>
  <div class="detail">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="main">
      <div class="class">当前选择分类: {{ $route.query.className }}</div>
      <br />
      <van-uploader :max-count="1" :before-read="befUpload" v-model="pic">
        <van-button icon="plus" type="primary">上传封面</van-button>
      </van-uploader>
      <!-- <img :src="obj.pic" alt="" /> -->
      <br />
      <br />
      <van-uploader
        :before-read="befUploadVideo"
        :max-count="1"
        accept="video/*"
        v-model="video"
      >
        <van-button icon="plus" type="primary">上传视频</van-button>
      </van-uploader>

      <van-field v-model="obj.title" label="标题" placeholder="请输入标题" />
      <p>规格</p>

      <van-cell-group>
        <van-field
          v-for="(v, i) in obj.msg"
          :key="i"
          v-model="v.subtitle"
          :label="v.title"
          type="textarea"
        >
          <template #button>
            <van-button size="small" type="danger" @click="delSpec(i)"
              >删除</van-button
            >
          </template>
        </van-field>
      </van-cell-group>
      <van-button type="info" style="width: 100%" @click="openSpec"
        >添加规格</van-button
      >
      <br />
      <br />
      <van-button type="primary" style="width: 100%" @click="save"
        >保存</van-button
      >
    </div>

    <van-dialog
      v-model="addSpec.show"
      title="添加规格"
      show-cancel-button
      @confirm="confirmBtn"
    >
      <van-field
        v-model="addSpec.title"
        label="规格名字"
        placeholder="请输入"
      />
      <van-field
        v-model="addSpec.subtitle"
        label="规格内容"
        placeholder="请输入"
        type="textarea"
      />
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { getSts, addShop, getDetail, updateShop } from "@/api/com";
import oss from "./oss";
export default {
  data() {
    return {
      pic: [],
      video: [],
      obj: {
        title: "",
        pic: "",
        video: "",
        msg: [],
        classId: "",
      },
      addSpec: {
        show: false,
        title: "",
        subtitle: "",
      },
    };
  },
  created() {
    let editId = this.$route.query.editId;
    if (editId) {
      this.getDetali(editId);
    }
  },
  methods: {
    async save() {
      let data = {
        title: this.obj.title,
        pic: this.obj.pic,
        video: this.obj.video,
        classId: this.$route.query.classId,
        msg: JSON.stringify(this.obj.msg),
      };
      if (this.$route.query.editId) {
        let res = await updateShop(data,this.$route.query.editId);
        if (res.code == 0) {
          Toast(res.msg);
          this.$router.push('/list')
        }
      } else {
        let res = await addShop(data);
        if (res.code == 0) {
          Toast(res.msg);
          this.$router.push('/list')
        }
      }
    },
    async befUpload(file) {
      let stsData = await getSts();
      let upres = await oss(stsData.data, file, 1);
      this.obj.pic = upres;
      return false;
    },
    async befUploadVideo(file) {
      let stsData = await getSts();
      let upres = await oss(stsData.data, file, 1);
      this.obj.video = upres;
      return false;
    },
    confirmBtn() {
      if (this.addSpec.title == "" || this.addSpec.subtitle == "") {
        Toast("请输入规格标题和内容");
        return;
      }
      this.obj.msg.push({
        title: this.addSpec.title,
        subtitle: this.addSpec.subtitle,
      });
      this.addSpec.title = "";
      this.addSpec.subtitle = "";
    },
    onClickLeft() {
      this.$router.push("/list");
    },
    openSpec() {
      this.addSpec.show = true;
    },
    delSpec(i) {
      console.log(i);
      let clone = JSON.parse(JSON.stringify(this.obj.msg));
      clone.splice(i, 1);
      this.obj.msg = clone;
    },
    async getDetali(id) {
      let res = await getDetail(id);
      console.log(res);
      this.obj = res.data;
    //   this.obj.msg = JSON.parse(res.data.msg);
      if (res.data.pic != "") {
        this.pic = [{ url: res.data.pic }];
      }
      if (res.data.video != "") {
        this.video = [{ url: res.data.video }];
      }
    },
  },
};
</script>

<style scoped>
.main {
  padding: 10px 20px;
}
</style>